<template>
  <dx-util-popup
    ref="historicalInventoryPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="historicalInventoryPopupShown"
    @hidden="historicalInventoryPopupHidden"
  >
  <dx-util-scroll-view width="100%" height="100%">
    <div class="px-1">
      <div class="row">
        <div class="col-3">
          <div class="bg-light-success px-1 py-1 mb-1 rounded">
            <div class="text-center pb-1">
              <p-icon name="feather-info" size="36px" class="mr-1" />
            </div>
            <div>
              <p>
                This chart provides a comprehensive overview of your inventory's historical values across
              <span class="text-warning">three critical stages:</span> (1) warehouse inbound, (2) warehouse inventory, and (3) Amazon inventory value.
              </p>
              <p>
                By displaying the total buy cost, total sell value, and total quantity of items at each stage,
                this chart enables you to identify trends, analyze patterns, and make informed decisions about
                your inventory management strategies.
              </p>
              <p>
                Whether you're looking to optimize your supply chain,
                reduce costs, or maximize profits, this chart provides valuable insights that can help you achieve your goals.
              </p>
            </div>
          </div>
          <div class="text-center">
            <h5>As of {{ valueDate }}</h5>
          </div>
          <div class="row align-items-left">
            <div class="d-flex p-half">
              <div style="width:20px;height: 20px;background-color: #1EB1F5;margin-right: 4px; margin-left: 4px;"></div>
              <h6 class="my-0">Total Buy Cost:
              <span class="text-warning">
                  {{ allTotalBuyCost }}
              </span> </h6>
            </div>
          </div>
          <div class="row align-items-left">
            <div class="d-flex p-half">
              <div style="width:20px;height: 20px;background-color: #4DAC79;margin-right: 4px; margin-left: 4px;"></div>
              <h6 class="my-0">Total Sell Value:
              <span class="text-warning">
                  {{ allTotalSellValue }}
              </span> </h6>
            </div>
          </div>
          <div class="row align-items-left">
            <div class="d-flex p-half">
              <div style="width:20px;height: 20px;background-color: #F77F00;margin-right: 4px; margin-left: 4px;"></div>
              <h6 class="my-0">Total Item Quantity
              <span class="text-warning">
                  {{ allTotalQuantity }}
              </span></h6>
            </div>
          </div>
          <div class="row align-items-left">
            <div class="d-flex p-half">
              <div style="width:20px;height: 20px;background-color: #D4ADFC;margin-right: 4px; margin-left: 4px;"></div>
              <h6 class="my-0">Inbound Avg Cost to Sale Value Ratio
              <span class="text-warning">
                  {{ inboundBuySellCostRatio }}%
              </span></h6>
            </div>
          </div>
          <div class="row align-items-left">
            <div class="d-flex p-half">
              <div style="width:20px;height: 20px;background-color: #D4ADFC;margin-right: 4px; margin-left: 4px;"></div>
              <h6 class="my-0">Inventory Avg Cost to Sale Value Ratio
              <span class="text-warning">
                  {{ inventoryBuySellCostRatio }}%
              </span></h6>
            </div>
          </div>
          <div class="row align-items-left">
            <div class="d-flex p-half">
              <div style="width:20px;height: 20px;background-color: #D4ADFC;margin-right: 4px; margin-left: 4px;"></div>
              <h6 class="my-0">AMZ Avg Cost to Sale Value Ratio
              <span class="text-warning">
                  {{ amzBuySellCostRatio }}%
              </span></h6>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div v-for="(chart, index) in charts" :key="index" class="border-bottom mb-2">
            <DxChart
              id="chart"
              :data-source="chartData"
              :title="chart.title"
              @pointClick="onPointClick"
            >
              <DxCommonSeriesSettings
                argument-field="date"
                type="bar"
                hover-mode="allArgumentPoints"
                selection-mode="allArgumentPoints"
              >
              </DxCommonSeriesSettings>
              <DxArgumentAxis>
                <DxLabel :customize-text="customizeText" />
              </DxArgumentAxis>
              <DxSeries
                :value-field="chart.buyField"
                name="Total Buy Cost"
                color="#1EB2F6"
              />
              <DxSeries
                :value-field="chart.salesField"
                name="Total Sell Value"
                color="#4DAC79"
              />
              <DxSeries
                :value-field="chart.quantityField"
                name="Total Quantity"
                axis="quantityAxis"
                type="spline"
                color="#F77F00"
              />
              <DxValueAxis :position="'left'" :show-zero="true">
                <DxLabel :visible="true" />
              </DxValueAxis>
              <DxValueAxis name="quantityAxis" :position="'right'" :show-zero="true" color="#F77F00">
                <DxLabel :visible="true">
                  <DxFont color="#F77F00" />
                </DxLabel>
              </DxValueAxis>
              <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
              />
              <DxExport :enabled="true" />
              <DxTooltip :enabled="true" :shared="true" :customize-tooltip="customizeTooltip" :z-index="10000">
                <DxFormat
                  :precision="1"
                  type="largeNumber"
                />
              </DxTooltip>
            </DxChart>
          </div>
        </div>
      </div>
    </div>
  </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxFont,
  DxLegend,
  DxExport,
  DxArgumentAxis,
  DxValueAxis,
  DxTooltip,
} from 'devextreme-vue/chart'
import { currencyFormatter, formatDate } from '@core/utils/filter'

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxFont,
    DxLegend,
    DxExport,
    DxArgumentAxis,
    DxValueAxis,
    DxTooltip,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      charts: [
        {
          title: 'Warehouse Inbound', salesField: 'inboundTotalSellValue', buyField: 'inboundTotalBuyValue', quantityField: 'inboundQuantity',
        },
        {
          title: 'Warehouse Inventory', salesField: 'inventoryTotalSellValue', buyField: 'inventoryTotalBuyValue', quantityField: 'inventoryQuantity',
        },
        {
          title: 'Amazon Inventory (Includes Amazon Inbound items)', salesField: 'amzTotalSellValue', buyField: 'amzTotalBuyValue', quantityField: 'amzTotalQuantity',
        },
      ],
    }
  },
  computed: {
    historicalInventoryPopup() {
      return this.$refs.historicalInventoryPopupRef.instance
    },
    latestData() {
      const latestData = this.chartData.reduce((latest, current) => {
        const currentDate = new Date(current.date)
        const latestDate = latest ? new Date(latest.date) : new Date(0)
        return currentDate > latestDate ? current : latest
      }, null)
      return latestData
    },
    valueDate() {
      return formatDate(this.latestData?.date)
    },
    allTotalBuyCost() {
      return currencyFormatter(this.latestData.inboundTotalBuyValue + this.latestData.amzTotalBuyValue + this.latestData.inventoryTotalBuyValue)
    },
    allTotalSellValue() {
      return currencyFormatter(this.latestData.inboundTotalSellValue + this.latestData.amzTotalSellValue + this.latestData.inventoryTotalSellValue)
    },
    allTotalQuantity() {
      return (this.latestData.inboundQuantity + this.latestData.amzTotalQuantity + this.latestData.inventoryQuantity).toLocaleString()
    },
    inboundBuySellCostRatio() {
      return (100 * (this.latestData.inboundTotalBuyValue / this.latestData.inboundTotalSellValue)).toFixed(2)
    },
    inventoryBuySellCostRatio() {
      return (100 * (this.latestData.inventoryTotalBuyValue / this.latestData.inventoryTotalSellValue)).toFixed(2)
    },
    amzBuySellCostRatio() {
      return (100 * (this.latestData.amzTotalBuyValue / this.latestData.amzTotalSellValue)).toFixed(2)
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.historicalInventoryPopup.show()
      },
    },
  },
  methods: {
    historicalInventoryPopupShown() {
      this.popupTitle = 'Historical Inventory Values'
    },
    historicalInventoryPopupHidden() {
      this.$emit('on-chart-closed')
    },
    closePopup() {
      this.historicalInventoryPopup.hide()
    },
    onPointClick() {

    },
    customizeText({ valueText }) {
      const day = valueText.substring(8, 10)
      const month = valueText.substring(5, 7)
      const year = valueText.substring(2, 4)
      const formattedDate = `${month}-${day}-${year}`
      return `${formattedDate}`
    },
    customizeTooltip(pointInfo) {
      const items = pointInfo.valueText.split('\n')
      const color = pointInfo.point.getColor()

      items.forEach((item, index) => {
        if (item.indexOf(pointInfo.seriesName) === 0) {
          const element = document.createElement('span')

          element.textContent = item
          element.style.color = color
          element.className = 'active'

          items[index] = element.outerHTML
        }
      })
      return { text: items.join('\n') }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
